var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header-page"},[_c('h4',[_vm._v(_vm._s(_vm.branch_name))]),_c('div',{staticClass:"mt-1",attrs:{"id":"inventory-summery"}},[_vm._v(" Quantity : "+_vm._s(_vm._f("numeral")(_vm.quantity,"0,0"))+" , Total Price : "+_vm._s(_vm._f("numeral")(_vm.total_price,"0,0.00"))+" ")])]),_c('b-tabs',{staticClass:"mt-4",model:{value:(_vm.tabsIndex),callback:function ($$v) {_vm.tabsIndex=$$v},expression:"tabsIndex"}},[_c('b-tab',{attrs:{"title":"Stock","active":""}},[_c('div',{staticClass:"bg-white p-2 p-lg-3"},[_c('div',{staticClass:"w-50"},[_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',{staticClass:"search-bar",attrs:{"type":"text","id":"header-search-bar","placeholder":"Search Stock name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('b-input-group-append',{staticClass:"mr-2",attrs:{"is-text":""}},[_c('b-iconstack',{attrs:{"font-scale":"2","type":"submit"},on:{"click":_vm.handleSearch}},[_c('b-icon',{attrs:{"stacked":"","icon":"search","scale":"0.5","variant":"grey"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"responsive":"","hover":"","fields":_vm.fields,"items":_vm.items,"busy":_vm.isBusy,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(name)",fn:function({ item }){return [_c('router-link',{staticClass:"text-underline",attrs:{"to":_vm.$route.params.id +
                    '/inventory/' +
                    item.stock_type_id +
                    '?name=' +
                    _vm.branch_name +
                    ' - ' +
                    item.name}},[_vm._v(_vm._s(item.name))])]}},{key:"cell(stock)",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.stock,"0,0"))+" ")]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-black my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]},proxy:true}])})],1)],1),_c('Pagination',{attrs:{"filter":_vm.filter,"rows":_vm.rows},on:{"handleChangeTake":_vm.handleChangeTake,"pagination":_vm.pagination}})],1)])],1),_c('FooterAction',{attrs:{"routePath":"/branch-management","hideSubmit":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }