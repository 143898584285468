<template>
  <div>
    <div class="header-page">
      <h4>{{ branch_name }}</h4>
      <div class="mt-1" id="inventory-summery">
        Quantity : {{ quantity | numeral("0,0") }} , Total Price :
        {{ total_price | numeral("0,0.00") }}
      </div>
      <!-- <div class="badge-inventory">ไม่รวมจำนวนติดลบ</div> -->
    </div>

    <b-tabs class="mt-4" v-model="tabsIndex">
      <b-tab title="Stock" active>
        <div class="bg-white p-2 p-lg-3">
          <div class="w-50">
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                id="header-search-bar"
                class="search-bar"
                @keyup.enter="handleSearch"
                placeholder="Search Stock name"
                v-model="filter.search"
              >
              </b-form-input>
              <b-input-group-append is-text class="mr-2">
                <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                  <b-icon
                    stacked
                    icon="search"
                    scale="0.5"
                    variant="grey"
                  ></b-icon>
                </b-iconstack>
              </b-input-group-append>
            </b-input-group>
          </div>

          <b-row>
            <b-col>
              <b-table
                responsive
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(name)="{ item }">
                  <router-link
                    :to="
                      $route.params.id +
                      '/inventory/' +
                      item.stock_type_id +
                      '?name=' +
                      branch_name +
                      ' - ' +
                      item.name
                    "
                    class="text-underline"
                    >{{ item.name }}</router-link
                  >
                </template>
                <template v-slot:cell(stock)="{ item }">
                  {{ item.stock | numeral("0,0") }}
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :filter="filter"
            :rows="rows"
            @pagination="pagination"
          />
        </div>
      </b-tab>
    </b-tabs>

    <FooterAction routePath="/branch-management" hideSubmit />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      filter: {
        search: "",
        page: 1,
        take: 10,
        log_type: [],
        branch_id: Number(this.$route.params.id),
      },
      isBusy: false,
      rows: 0,
      quantity: 0,
      total_price: 0,

      tabsIndex: 0,
      productType: [
        { text: "ZFIN", value: "ZFIN" },
        { text: "ZLPR", value: "ZLPR" },
        { text: "ZPACK", value: "ZPACK" },
        { text: "ZSAM", value: "ZSAM" },
        { text: "ZSER", value: "ZSER" },
      ],
      fields: [
        {
          key: "name",
          label: "Stock Name",
        },

        {
          key: "stock",
          label: "Total",
        },
      ],

      items: [],
      movementOptions: [],
      movementSelect: false,
      stockSelect: false,
      stockOptions: [],
      modalShow: false,
      filename: "",
      form: {
        excel_file: "",
        branch_id: this.$route.params.id,
      },
      isDisable: false,
      branch_name: "",
    };
  },
  mounted() {
    this.getInventory();
  },
  watch: {},
  methods: {
    handleOnFilter() {
      this.getInventory();
    },

    async getInventory() {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.price_min = payload.price_min == "" ? null : payload.price_min;
      payload.price_max = payload.price_max == "" ? null : payload.price_max;
      payload.available_min =
        payload.available_min == "" ? null : payload.available_min;
      payload.available_max =
        payload.available_max == "" ? null : payload.available_max;
      const response = await this.axios.post(
        `/Inventory/GetInventoryStock/${Number(this.$route.params.id)}`,
        payload
      );

      this.items = response.data.detail.data.map((el) => {
        return {
          ...el,
          showDetails: true,
        };
      });
      this.rows = response.data.detail.count;
      this.quantity = response.data.detail.quantity;
      this.total_price = response.data.detail.total_price;
      this.branch_name = response.data.detail.branch_name;
      this.isBusy = false; // api
    },

    handleSearch() {
      this.getInventory();
    },
    clearModal() {
      this.modalShow = false;
      this.form.excel_file = "";
      this.filename = "";
    },
    getStockList() {},
    clearFilter() {
      this.productSelect = true;
      this.filter = {
        search: "",
        page: 1,
        price_min: "",
        price_max: "",
        available_min: "",
        available_max: "",
        take: 10,
        branch_id: Number(this.$route.params.id),
        product_type: ["ZFIN", "ZLPR", "ZPACK", "ZSAM", "ZSER"],
      };
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        log_type: [],
        branch_id: Number(this.$route.params.id),
      };
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },

    handleChangeTake(val) {
      this.filter.page = 1;
      this.filter.take = val;
      this.getInventory();
    },
    pagination(val) {
      this.filter.page = val;

      this.getInventory();
    },
    openModal(items) {
      this.$refs["modal-edit-stock"].show(items);
    },
  },
};
</script>

<style lang="scss" scoped>
#inventory-summery {
  position: relative;
  font-weight: normal;
  max-width: fit-content;
  &::after {
    position: absolute;
    border-radius: 5px;
    padding: 0px 5px;
    background: red;
    color: white;
    content: "ไม่รวมจำนวนติดลบ";
    bottom: -75%;
    white-space: nowrap;
    left: 0;
    font-size: 10px;
  }
}
::v-deep .width-btn {
  min-width: 7rem;
}
::v-deep .dropdown-toggle.btn-secondary {
  background-color: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
::v-deep .dropdown-menu {
  width: fit-content;
  li:hover {
    text-decoration: none;
    background-color: #fff;
    color: var(--primary-color) !important;
  }
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
::v-deep .filter-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  gap: 1rem;
}

.header-page {
  color: black;
  font-weight: bold;
  h4 {
    font-size: 25px;
    // &::after {
    // }
  }
}
::v-deep .dropdown-menu li:hover {
  color: #000000 !important;
}
::v-deep .inventory-table table th {
  width: 16.67%;
}
</style>
